exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-osdn-applications-and-forms-connection-to-the-network-index-js": () => import("./../../../src/pages/osdn/applications-and-forms/connection-to-the-network/index.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-connection-to-the-network-index-js" */),
  "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-certification-of-manufacturing-units-js": () => import("./../../../src/pages/osdn/applications-and-forms/connection-to-the-network/ncrfg/certification-of-manufacturing-units.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-certification-of-manufacturing-units-js" */),
  "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-general-information-js": () => import("./../../../src/pages/osdn/applications-and-forms/connection-to-the-network/ncrfg/general-information.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-general-information-js" */),
  "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-index-js": () => import("./../../../src/pages/osdn/applications-and-forms/connection-to-the-network/ncrfg/index.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-index-js" */),
  "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-modernization-of-generation-units-js": () => import("./../../../src/pages/osdn/applications-and-forms/connection-to-the-network/ncrfg/modernization-of-generation-units.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-modernization-of-generation-units-js" */),
  "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-permission-abc-js": () => import("./../../../src/pages/osdn/applications-and-forms/connection-to-the-network/ncrfg/permission-abc.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-permission-abc-js" */),
  "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-permission-d-js": () => import("./../../../src/pages/osdn/applications-and-forms/connection-to-the-network/ncrfg/permission-d.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-permission-d-js" */),
  "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-testing-of-manufacturing-units-js": () => import("./../../../src/pages/osdn/applications-and-forms/connection-to-the-network/ncrfg/testing-of-manufacturing-units.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-connection-to-the-network-ncrfg-testing-of-manufacturing-units-js" */),
  "component---src-pages-osdn-applications-and-forms-connection-to-the-network-producer-application-for-notification-of-a-micro-installation-js": () => import("./../../../src/pages/osdn/applications-and-forms/connection-to-the-network/producer/application-for-notification-of-a-micro-installation.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-connection-to-the-network-producer-application-for-notification-of-a-micro-installation-js" */),
  "component---src-pages-osdn-applications-and-forms-connection-to-the-network-producer-connection-application-for-producers-js": () => import("./../../../src/pages/osdn/applications-and-forms/connection-to-the-network/producer/connection-application-for-producers.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-connection-to-the-network-producer-connection-application-for-producers-js" */),
  "component---src-pages-osdn-applications-and-forms-connection-to-the-network-producer-index-js": () => import("./../../../src/pages/osdn/applications-and-forms/connection-to-the-network/producer/index.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-connection-to-the-network-producer-index-js" */),
  "component---src-pages-osdn-applications-and-forms-connection-to-the-network-receiver-js": () => import("./../../../src/pages/osdn/applications-and-forms/connection-to-the-network/receiver.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-connection-to-the-network-receiver-js" */),
  "component---src-pages-osdn-applications-and-forms-index-js": () => import("./../../../src/pages/osdn/applications-and-forms/index.js" /* webpackChunkName: "component---src-pages-osdn-applications-and-forms-index-js" */),
  "component---src-pages-osdn-connection-capacities-connection-conditions-issued-above-1-kv-js": () => import("./../../../src/pages/osdn/connection-capacities/connection-conditions-issued/above-1kv.js" /* webpackChunkName: "component---src-pages-osdn-connection-capacities-connection-conditions-issued-above-1-kv-js" */),
  "component---src-pages-osdn-connection-capacities-connection-conditions-issued-index-js": () => import("./../../../src/pages/osdn/connection-capacities/connection-conditions-issued/index.js" /* webpackChunkName: "component---src-pages-osdn-connection-capacities-connection-conditions-issued-index-js" */),
  "component---src-pages-osdn-connection-capacities-connection-conditions-issued-less-1-kv-js": () => import("./../../../src/pages/osdn/connection-capacities/connection-conditions-issued/less-1kv.js" /* webpackChunkName: "component---src-pages-osdn-connection-capacities-connection-conditions-issued-less-1-kv-js" */),
  "component---src-pages-osdn-connection-capacities-index-js": () => import("./../../../src/pages/osdn/connection-capacities/index.js" /* webpackChunkName: "component---src-pages-osdn-connection-capacities-index-js" */),
  "component---src-pages-osdn-contract-templates-index-js": () => import("./../../../src/pages/osdn/contract-templates/index.js" /* webpackChunkName: "component---src-pages-osdn-contract-templates-index-js" */),
  "component---src-pages-osdn-contract-templates-template-comprehensive-agreement-index-js": () => import("./../../../src/pages/osdn/contract-templates/template-comprehensive-agreement/index.js" /* webpackChunkName: "component---src-pages-osdn-contract-templates-template-comprehensive-agreement-index-js" */),
  "component---src-pages-osdn-contract-templates-template-distribution-agreement-index-js": () => import("./../../../src/pages/osdn/contract-templates/template-distribution-agreement/index.js" /* webpackChunkName: "component---src-pages-osdn-contract-templates-template-distribution-agreement-index-js" */),
  "component---src-pages-osdn-current-tariff-index-js": () => import("./../../../src/pages/osdn/current-tariff/index.js" /* webpackChunkName: "component---src-pages-osdn-current-tariff-index-js" */),
  "component---src-pages-osdn-current-tariff-tariff-js": () => import("./../../../src/pages/osdn/current-tariff/tariff.js" /* webpackChunkName: "component---src-pages-osdn-current-tariff-tariff-js" */),
  "component---src-pages-osdn-index-js": () => import("./../../../src/pages/osdn/index.js" /* webpackChunkName: "component---src-pages-osdn-index-js" */),
  "component---src-pages-osdn-instructions-and-standards-index-js": () => import("./../../../src/pages/osdn/instructions-and-standards/index.js" /* webpackChunkName: "component---src-pages-osdn-instructions-and-standards-index-js" */),
  "component---src-pages-osdn-instructions-and-standards-iriesd-index-js": () => import("./../../../src/pages/osdn/instructions-and-standards/iriesd/index.js" /* webpackChunkName: "component---src-pages-osdn-instructions-and-standards-iriesd-index-js" */),
  "component---src-pages-osdn-instructions-and-standards-technical-standards-index-js": () => import("./../../../src/pages/osdn/instructions-and-standards/technical-standards/index.js" /* webpackChunkName: "component---src-pages-osdn-instructions-and-standards-technical-standards-index-js" */),
  "component---src-pages-osdn-quality-parameters-index-js": () => import("./../../../src/pages/osdn/quality-parameters/index.js" /* webpackChunkName: "component---src-pages-osdn-quality-parameters-index-js" */)
}

